import { useEffect, useState } from "react";
import LegacyPage from "../subcomponents/LegacyPage";
import { useFetch, useRequest, useTranslate, useUpdateRecord } from "../providers/AppProvider";
import server_route_names from "../routes/server_route_names";
import Layout from "../components/Layout";
import { Alert, Button, LinearProgress, Paper, Stack, TableContainer, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { styled } from '@mui/material/styles';
import Toast from "../components/Toast";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .even`]: {
    backgroundColor: theme.palette.mode === "light"? theme.palette.grey[100]: theme.palette.background.default
  },
  '& .MuiDataGrid-cell': {
    borderRight: "1px solid",
    borderColor: theme.palette.divider,
  },
  '& .MuiDataGrid': {
    borderColor: theme.palette.divider,
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell.MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === "light"? theme.palette.grey.A200: null
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
    backgroundColor: theme.palette.mode === "light"? theme.palette.grey.A200: null
  },
}));

export default function Collections(){
    const { t } = useTranslate();
    const [paginationModel, onPaginationModelChange] = useState({ page: 0, pageSize: 10 });
    const [searchTerms, setSearchTerms] = useState("");

    let url = `${server_route_names.collections}?limit=${paginationModel.pageSize}&page=${paginationModel.page+1}`;
    if (searchTerms && String(searchTerms).trim() !== "") {
        url += `&q=${searchTerms}&qMatchWith=title,handle,collection_number`
    }

    const {data: products, fetching: loading, error, reFetch } = useFetch(url);
    const { data: sync_data, error: sync_error, sendRequest: syncCollection } = useRequest();
    const { data: updated_record, updateRecord, error: update_record_error } = useUpdateRecord(`${server_route_names["collections.map"]}`);

    const [closeSyncAlert, setCloseSyncAlert] = useState(false);

    useEffect(() => {
        document.title = t("collections.title");
    }, []);

    const onMapClick = async function (collection){
        let response = await updateRecord(collection?._id, {collection});
        if (response?.status === "success") {
            // reFetch();
        }
    }

    if (error) {
        return(
            <LegacyPage
                title={t("collections.title")}
            >
                {error}
            </LegacyPage>
        )
    }

    return(
        <LegacyPage
            title={t("collections.title")}
            actions={[
                {
                    title: t("collections.refresh"),
                    onClick: async () => {
                        reFetch();
                    }
                },
                {
                    title: t("collections.synchronize"),
                    onClick: async () => {
                        await syncCollection(`${server_route_names["collections.sync"]}`);
                        setCloseSyncAlert(false);
                    }
                }
            ]}
            slots={
                <Stack>
                    <TextField
                        component={Paper}
                        type="search"
                        prefix="$"
                        value={searchTerms}
                        onChange={(e) => {
                            setSearchTerms(e.target.value);
                        }}
                        placeholder={t("collections.search_field")}
                    ></TextField>
                </Stack>
            }
        >
            {
                updated_record && <Toast
                    message={updated_record?.message??"Updated successfully"}
                    open={true}
                ></Toast>
            }
            {
                update_record_error && <Toast
                    message={update_record_error??"Server error"}
                    open={true}
                    type="error"
                ></Toast>
            }
            {
                sync_error && <Toast
                    message={sync_error??"Server error"}
                    open={true}
                    type="error"
                ></Toast>
            }
            <Layout>
                {
                    (sync_data && sync_data?.message && !closeSyncAlert) && <Layout.Section>
                        <Alert title={sync_data?.message} closeText="Close" onClose={() => {
                            setCloseSyncAlert(true);
                        }}>{sync_data?.message}</Alert>
                    </Layout.Section>
                }
                {
                    (sync_error && sync_error?.message && !closeSyncAlert) && <Layout.Section>
                        <Alert severity="error" title={sync_error?.message} closeText="Close" onClose={() => {
                            setCloseSyncAlert(true);
                        }}>{sync_error?.message}</Alert>
                    </Layout.Section>
                }
                <Layout.Section>
                    <TableContainer component={Paper}>
                        <StripedDataGrid
                            slots={{
                                loadingOverlay: LinearProgress
                            }}
                            sx={{ height: (parseInt(products?.items?.length)||0) === 0 ? 400 : null }}
                            rows={products?.items??[]}
                            rowCount={products?.total??0}
                            loading={loading}
                            columns={columns(onMapClick, t)}
                            paginationMode="server"
                            paginationModel={paginationModel}
                            onPaginationModelChange={onPaginationModelChange}
                            initialState={{ paginationModel: paginationModel }}
                            pageSizeOptions={[10,25,50,100]}
                            disableColumnFilter
                            disableColumnMenu
                            disableRowSelectionOnClick
                        ></StripedDataGrid>
                    </TableContainer>
                    <Stack direction="column" spacing={1} sx={{mt: 3}}>
                        <Typography variant="caption" sx={{mt: 5}}>
                            {t("collections.footer_note_1")}
                        </Typography>
                        <Typography variant="caption">
                            {t("collections.footer_note_2")}
                        </Typography>
                    </Stack>
                </Layout.Section>
            </Layout>
        </LegacyPage>
    )
}

function columns(onMapClick, t){
    const c = [
        {
            field: "title",
            headerName: t("collections.collection name"),
            width: 300,
            sortable: false,
        },
        {
            field: "collection_number",
            headerName: t("collections.collection number"),
            width: 140,
            sortable: false,
            editable: true,
        },
        {
            field: "available_number",
            headerName: t("collections.Next available number"),
            width: 180,
            sortable: false,
            editable: true,
        },
        {
            field: "updatedAt",
            headerName: t("collections.Last updated"),
            width: 140,
            sortable: false,
            editable: true,
            renderCell: params => {
                return <Stack direction="column">
                    <div>
                        {moment(params.row.updatedAt).fromNow()}
                    </div>
                </Stack>;
            }
        },
        {
            field: "actions",
            headerName: t("collections.Actions"),
            width: 180,
            sortable: false,
            renderCell: params => {
                return <Stack>
                    <div>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                onMapClick(params.row)
                            }}
                            disabled={params?.row?.collection_number === null || params?.row?.collection_number === undefined}
                        >{t("collections.map_value")}</Button>
                    </div>
                </Stack>;
            }
        }
    ];
    return c;
}