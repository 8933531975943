export const locals = {
    "app": {
        "fullName": "Benutzerdefinierte SKUs: Private Shopify-App"
    },
    "home": {
        "title": "Startseite",
        "welcome": "Willkommen"
    },
    "search": {
        "placeholder": "Suchen"
    },
    "nav": {
        "products": "Produkte",
        "collections": "Sammlungen",
        "divider": "Trennzeichen",
        "accounts": "KONTEN",
        "My Account": "Mein Konto",
        "My Team": "Mein Team",
        "Add Staff": "Mitarbeiter hinzufügen",
        "Edit Staff": "Mitarbeiter bearbeiten"
    },
    "login": {
        "title": "Anmelden",
        "buttonLabel": "Anmelden",
        "rememberMe": "Erinnere mich",
        "forgotPasswordLink": "Passwort vergessen?",
        "logout": "Abmelden"
    },
    "forgotPassword": {
        "title": "Passwort vergessen",
        "buttonLabel": "Passwort zurücksetzen"
    },
    "forms": {
        "email": "E-Mail-Adresse",
        "phone": "Telefon",
        "password": "Passwort",
        "name": "Vollständiger Name",
        "fname": "Vorname",
        "lname": "Nachname",
        "confirmPassword": "Passwort bestätigen",
        "oldPassword": "Altes Passwort",
        "newPassword": "Neues Passwort",
        "retypeNewPassword": "Neues Passwort erneut eingeben",
        "Account status": "Kontostatus"
    },
    "register": {
        "title": "Ein Konto erstellen",
        "buttonLabel": "Ein neues Konto erstellen"
    },
    "products": {
        "title": "Produkte",
        "refresh": "Aktualisieren",
        "synchronize": "Synchronisieren",
        "create_bulk_skus": "SKUs in Massen erstellen",
        "create_update_skus": "SKUs erstellen/aktualisieren",
        "search_field": "Produkt nach Titel durchsuchen",
        "selected": "Ausgewählt",
        "singular": "Produkt",
        "plural": "Produkte",
        "Product title": "Produktname",
        "variants": "Varianten",
        "Last updated": "Zuletzt aktualisiert",
        "Status": "Status",
        "Actions": "Aktionen"
    },
    "collections": {
        "title": "Sammlungen",
        "refresh": "Aktualisieren",
        "synchronize": "Synchronisieren",
        "map_value": "Wert zuordnen",
        "search_field": "Sammlung nach Titel durchsuchen",
        "singular": "Sammlung",
        "plural": "Sammlungen",
        "footer_note_1": "Das einzige bearbeitbare Feld ist die Sammlungsnummer, die in Shopify-Sammlungen unverändert bleibt. Diese Funktion ordnet ausschließlich Sammlungsnummern zu, um benutzerdefinierte SKUs zu erstellen.",
        "footer_note_2": "Um zu bearbeiten, bitte doppelt auf die Zelle der Sammlungsnummer klicken, den Wert eingeben und dann auf die Schaltfläche \"Wert zuordnen\" klicken.",
        "collection name": "Sammlungsname",
        "collection number": "Sammlungsnummer",
        "Next available number": "Nächste verfügbare Nummer",
        "Last updated": "Zuletzt aktualisiert",
        "Actions": "Aktionen"
    },
    "my account": {
        "title": "Mein Konto",
        "heading": "Mein Profil",
        "info": "Verwalten Sie Ihre persönlichen Informationen",
        "security": "Sicherheit",
        "security info": "Verwalten Sie Ihre Passwörter und Anmeldungen",
        "update button": "Profil aktualisieren",
        "update password": "Passwort aktualisieren"
    },
    "my staff": {
        "title": "Mein Team",
        "add staff": "Mitarbeiter hinzufügen",
        "footer note 1": "Um die Berechtigungen von Mitarbeitern zu ändern oder ihren Kontostatus anzupassen, klicken Sie einfach doppelt auf die Zelle/Zeile mit ihrem vollständigen Namen."
    },
    "add staff": {
        "title": "Mitglied einladen",
        "staff": "Mitarbeiter einladen",
        "add staff": "Mitarbeiter hinzufügen",
        "invite button": "Einladung senden",
        "info": "Mitarbeiter können sensible Geschäftsinformationen wie Finanzdaten nur einsehen oder verwalten, wenn Sie ihnen Zugriff gewähren.",
        "footer note 1": "Um die Berechtigungen von Mitarbeitern zu ändern oder ihren Kontostatus anzupassen, klicken Sie einfach doppelt auf die Zelle/Zeile mit ihrem vollständigen Namen."
    },
    "edit staff": {
        "title": "Mitarbeiter bearbeiten",
        "heading": "Mitarbeiter",
        "info": "Geben Sie Mitarbeitern Zugriff auf Ihre Software, indem Sie ihnen eine Einladung senden.",
        "button text": "Benutzer aktualisieren"
    },
    "permissions": {
        "title": "Berechtigungen",
        "PRODUCTS_MANAGE": "Produkte verwalten",
        "COLLECTIONS_MANAGE": "Sammlungen verwalten",
        "SKU_CREATE_UPDATE": "SKUs erstellen & aktualisieren",
        "STAFF_MANAGE": "Mitarbeiter verwalten"
    },
    "language": {
        "select": "Sprache auswählen"
    }
}

