import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useNavigate } from 'react-router-dom';
import route_names from '../../routes/route_names';
import { useAuth } from '../../providers/AuthProvider';
import Logo from './Logo';
import { NameAvatar } from '../../components/CustomAvatar';
import { useThemeMode } from '..';
import { useTranslate } from '../../providers/AppProvider';
 
const AppTopBar = (props) => {
  const { onNavOpen } = props;
  
  const theme = useTheme();
  const colorMode = useThemeMode();

  const { user, logout } = useAuth();
  const { t } = useTranslate();
  const navigate = useNavigate();

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => {
        handleMenuClose();
        navigate(route_names.myaccount);
      }}>
        {t("nav.My Account")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
          handleMobileMenuClose();
          logout();
        }}
      >
        {t("login.logout")}
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
       <MenuItem onClick={() => {
          handleMenuClose();
          navigate(route_names.myaccount);
        }}>
          <Stack spacing={1} direction="row" alignItems="center">
            <NameAvatar name={`${user?.firstName} ${user?.lastName}`} />
            <Typography>
              {user?.firstName} {user?.lastName}
            </Typography>
          </Stack>
        </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backdropFilter: 'blur(6px)',
          boxShadow: "none",
          // backgroundColor: blueGrey[900],
          // color: common.white,
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        color="primary"
        // enableColorOnDark
      >
          <Toolbar>
            <Logo>
            {
              !lgUp && (<IconButton
                onClick={onNavOpen}
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>)
            }
            </Logo>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Stack spacing={1} direction="row" alignItems="center">
                  <NameAvatar name={user?.fullName}></NameAvatar>
                  <Typography color="inherit">
                    {user?.fullName}
                  </Typography>
                </Stack>
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMobileMenu}
    </>
  );
}

export default AppTopBar;