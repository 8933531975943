const server_route_names = {
    "users": "/api/users",
    "users.update": "/api/users/update",
    "password.forgot": "/auth/account/password/forgot",
    "otp.resend": "/auth/account/otp/resend",
    "account.activate": "/auth/account/activate",
    "account.password.update": "/auth/account/password/update",
    "account.verify": "/auth/account/verify_user",
    "account.login": "/auth/login",
    "account.register": "/auth/register",
    "account.verify.token": "/auth/account/verify_token",
    "account.invitation.accept": "/auth/account/accept_invitation",
    "account.password.create": "/auth/account/password/create",
    "user.invite": "/api/staff/add",
    "myaccount": "/api/myaccount",
    "products": "/shopify/products",
    "products.create_sku": "/shopify/products/create_sku",
    "products.create_bulk_sku": "/shopify/products/create_bulk_sku",
    "products.sync": "/shopify/products/sync",
    "collections": "/shopify/collections",
    "collections.map": "/shopify/collections/map",
    "collections.sync": "/shopify/collections/sync",
};

export default server_route_names;