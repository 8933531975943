import { useEffect, useState } from "react";
import LegacyPage from "../subcomponents/LegacyPage";
import { useCreateRecord, useFetch, useRequest, useTranslate } from "../providers/AppProvider";
import server_route_names from "../routes/server_route_names";
import Layout from "../components/Layout";
import { Alert, Button, Checkbox, Chip, FormControl, ListItemText, MenuItem, Paper, Select, Stack, TableContainer, TextField, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { styled } from '@mui/material/styles';
import Toast from "../components/Toast";
import LinearProgress from '@mui/material/LinearProgress';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .even`]: {
    backgroundColor: theme.palette.mode === "light"? theme.palette.grey[100]: theme.palette.background.default
  },
//   '& .MuiDataGrid-cell': {
//     borderRight: "1px solid",
//     borderColor: theme.palette.divider,
//   },
  '& .MuiDataGrid': {
    borderColor: theme.palette.divider,
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell.MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === "light"? theme.palette.grey.A200: null
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
    backgroundColor: theme.palette.mode === "light"? theme.palette.grey.A200: null
  },
}));

export default function Products(){
    const { t } = useTranslate();
    const [paginationModel, onPaginationModelChange] = useState({ page: 0, pageSize: 10 });
    const [productSelection, setProductSelection] = useState([]);
    const [searchTerms, setSearchTerms] = useState("");
    const [sortBy, setSortBy] = useState(sort_by_list);
    
    let url = `${server_route_names.products}?limit=${paginationModel.pageSize}&page=${paginationModel.page+1}`;
    if (searchTerms && String(searchTerms) !== "") {
        url += `&q=${String(searchTerms).trim()}&qMatchWith=title,handle,status`;
    }
    if (sortBy && sortBy.length > 0) {
        sortBy.forEach(x => {
            url += `&status=${String(x).toLowerCase()}`;
        });
    }
    const { data: products, fetching: loading, error, reFetch } = useFetch(url);
    const { data: create_sku_data, createRecord: createSku, error: create_sku_error } = useCreateRecord(`${server_route_names["products.create_sku"]}`);
    const { data: create_bulk_sku_data, createRecord: createBulkSKUs, error: create_bulk_sku_error } = useCreateRecord(`${server_route_names["products.create_bulk_sku"]}`);
    const { data: sync_data, error: sync_error, sendRequest: syncProduct } = useRequest();
    const [closeSyncAlert, setCloseSyncAlert] = useState(false);

    useEffect(() => {
        document.title = t("products.title");
    }, []);

    const createSKUs = async (product) => {
        let response = await createSku({product});
        if (response?.status === "success") {
            // reFetch();
        }
    }

    const createBulkSku = async () => {
        let response = await createBulkSKUs({
            product: {
                ids: productSelection
            }
        });
        if (response?.status === "success") {
            setProductSelection([]);
        }
    }

    if (error) {
        return(
            <LegacyPage
                title={t("products.title")}
            >
                {error}
            </LegacyPage>
        );
    }

    return(
        <LegacyPage
            title={t("products.title")}
            actions={[
                {
                    title: t("products.refresh"),
                    onClick: async () => {
                        reFetch();
                    }
                },
                {
                    title: t("products.synchronize"),
                    onClick: async () => {
                        await syncProduct(`${server_route_names["products.sync"]}`);
                    }
                }
            ]}
            headerSlots={<Stack direction="row" spacing={1}>
                {
                    (productSelection?.length > 0) &&
                    <Button
                        color="inherit"
                        size="small"
                        disabled
                    >
                        {productSelection?.length} {productSelection?.length > 1?t("products.plural"):t("products.singular")} {t("products.selected")}
                    </Button>
                }
                {
                    (productSelection?.length > 0) && <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={createBulkSku}
                        disabled={productSelection?.length < 1}
                    >
                        {t("products.create_bulk_skus")}
                    </Button>
                }
            </Stack>}
            slots={
                <Stack direction="row" spacing={2} alignItems="baseline" flexWrap="nowrap">
                    <Stack width="80%">
                        <TextField
                            component={Paper}
                            type="search"
                            value={searchTerms}
                            onChange={(e) => {
                                setSearchTerms(e.target.value);
                            }}
                            placeholder={t("products.search_field")}
                        />
                    </Stack>
                    <Stack width="20%">
                        <FormControl component={Paper} size="small">
                            <Select
                                labelId="sort-select-label"
                                id="demo-simple-select"
                                value={sortBy}
                                placeholder="Sort by Status"
                                multiple
                                renderValue={(selected) => selected.join(', ')}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setSortBy(typeof value === 'string' ? value.split(',') : value);
                                }}
                            >
                                {
                                    sort_by_list.map((x, i) => (
                                        <MenuItem key={`sort-by-k_${i+1}`} value={x}>
                                            <Checkbox checked={sortBy.indexOf(x) > -1} />
                                            <ListItemText primary={x} />
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
            }
        >
            {
                create_sku_data && <Toast
                    message={create_sku_data?.message??"SKUs have been created successfully"}
                    open={true}
                ></Toast>
            }
            {
                create_sku_error && <Toast
                    message={create_sku_error??"Server error"}
                    type="error"
                    open={true}
                ></Toast>
            }
            {
                create_bulk_sku_data && <Toast
                    message={create_bulk_sku_data?.message??"Request received, and processing to generate SKUs."}
                    open={true}
                ></Toast>
            }
            {
                create_bulk_sku_error && <Toast
                    message={create_bulk_sku_error??"Server error"}
                    type="error"
                    open={true}
                ></Toast>
            }
            <Layout>
                {
                    (sync_data && sync_data?.message && !closeSyncAlert) && <Layout.Section>
                        <Alert title={sync_data?.message} closeText="Close" onClose={() => {
                            setCloseSyncAlert(true);
                        }}>{sync_data?.message}</Alert>
                    </Layout.Section>
                }
                {
                    (sync_error && sync_error?.message && !closeSyncAlert) && <Layout.Section>
                        <Alert severity="error" title={sync_error?.message} closeText="Close" onClose={() => {
                            setCloseSyncAlert(true);
                        }}>{sync_error?.message}</Alert>
                    </Layout.Section>
                }
                <Layout.Section>
                    <TableContainer component={Paper}>
                        <StripedDataGrid
                            slots={{
                                loadingOverlay: LinearProgress,
                                toolbar: GridToolbar
                            }}
                            slotProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
                            sx={{ height: (parseInt(products?.items?.length)||0) === 0 ? 400 : null }}
                            rows={products?.items??[]}
                            rowCount={products?.total??0}
                            loading={loading}
                            columns={columns(createSKUs, t)}
                            paginationMode="server"
                            paginationModel={paginationModel}
                            onPaginationModelChange={onPaginationModelChange}
                            initialState={{ paginationModel: paginationModel }}
                            pageSizeOptions={[10,25,50,100]}
                            disableColumnFilter
                            disableColumnMenu
                            disableRowSelectionOnClick
                            checkboxSelection
                            rowSelectionModel={productSelection}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setProductSelection(newRowSelectionModel);
                            }}
                            keepNonExistentRowsSelected
                        ></StripedDataGrid>
                    </TableContainer>
                </Layout.Section>
            </Layout>
        </LegacyPage>
    )
}

function columns(createSKUs, t){
    const c = [
        {
            field: "title",
            headerName: t("products.Product title"),
            width: 440,
            sortable: false,
            // headerAlign: "center",
            // align: "center",
            hideable: false,
            renderCell: params => {
                let skus = [];
                if (params?.row?.variants?.length) {
                    for (let index = 0; index < params?.row?.variants.length; index++) {
                        const variant = params?.row?.variants[index];
                        if (variant?.sku && variant !== null) {
                            skus.push(variant?.sku);
                        }
                    }
                }
                return <Stack direction="column">
                    <Typography>
                        {params?.row?.title}
                    </Typography>
                    <Typography color="text.secondary" variant="caption">
                        {skus.toString()}
                    </Typography>
                </Stack>
            }
        },
        {
            field: "variant",
            headerName: t("products.variants"),
            width: 100,
            sortable: false,
            renderCell: params => {
                return <Stack direction="column">
                    <Typography>
                        {params?.row?.variants?.length} {params?.row?.variants?.length>1?"variants":"variant"}
                    </Typography>
                </Stack>
            }
        },
        {
            field: "updatedAt",
            headerName: t("products.Last updated"),
            width: 120,
            sortable: false,
            renderCell: params => {
                return <Stack direction="column">
                    <div>
                        {moment(params.row.updatedAt).fromNow()}
                    </div>
                    {/* <Typography color="text.secondary" variant="caption">
                        Shopify: {moment(params.value).fromNow()}
                    </Typography> */}
                </Stack>;
            }
        },
        {
            field: "status",
            headerName: t("products.Status"),
            width: 100,
            sortable: false,
            // headerAlign: "center",
            // align: "center",
            renderCell: params => {
                const status = params?.value;
                if (status) {
                    return <Chip disabled={status === "archived"} variant="outlined" color={status === "active"?"success":"default"} label={status}></Chip>;
                }
                return null;
            }
        },
        {
            field: "actions",
            headerName: t("products.Actions"),
            width: 200,
            sortable: false,
            renderCell: params => {
                return <Stack>
                    <div>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                createSKUs(params.row);
                            }}
                        >{t("products.create_update_skus")}</Button>
                    </div>
                </Stack>;
            }
        }
    ];
    return c;
}

const sort_by_list = [
    "Active",
    "Draft",
    "Archived"
]