import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CollectionsIcon from '@mui/icons-material/Collections';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import route_names from "./route_names";
import MyAccount from "../pages/users/MyAccount";
import MyStaff from '../pages/users/MyStaff';
import AddMyStaff from '../pages/users/AddMyStaff';
import EditMyStaff from '../pages/users/EditMyStaff';
import Products from '../pages/Products';
import privileges from '../privileges';
import Collections from '../pages/Collections';

export const navigation = [
    {
        path: route_names.index,
        element: <Products />,
        isMenu: false,
    },
    {
        title: 'products',
        path: route_names.products,
        element: <Products />,
        icon: <LocalOfferIcon />,
        isMenu: true,
        position: 1,
        permissions: true,
    },
    {
        title: 'collections',
        path: route_names.collections,
        element: <Collections />,
        icon: <CollectionsIcon />,
        isMenu: true,
        position: 2,
        permissions: true,
    },
    {
        type: "divider",
        position: 100
    },
    {
        title: 'accounts',
        type: "heading",
        position: 101
    },
    {
        title: 'My Account',
        path: route_names["myaccount"],
        element: <MyAccount />,
        icon: <AccountBoxIcon />,
        isMenu: true,
        position: 103,
        permissions: true
    },
    {
        title: 'My Team',
        path: route_names['management.staff'],
        element: <MyStaff />,
        icon: <GroupsIcon />,
        isMenu: true,
        position: 102,
        permissions: privileges['staff.manage']
    },
    {
        title: 'Add Staff',
        path: route_names['management.staff.add'],
        element: <AddMyStaff />,
        isMenu: false,
        permissions: privileges['staff.manage']
    },
    {
        title: 'Edit Staff',
        path: route_names['management.staff.edit'],
        element: <EditMyStaff />,
        isMenu: false,
        permissions: privileges['staff.manage']
    },
];
