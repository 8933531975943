export const locals = {
    "app": {
        "fullName": "Custom SKUs: Private Shopify App"
    },
    "home": {
        "title": "Home",
        "welcome": "Welcome"
    },
    "search": {
        "placeholder": "Search"
    },
    "nav": {
        "products": "Products",
        "collections": "Collections",
        "divider": "Divider",
        "accounts": "ACCOUNTS",
        "My Account": "My Account",
        "My Team": "My Team",
        "Add Staff": "Add Staff",
        "Edit Staff": "Edit Staff"
    },
    "login": {
        "title": "Sign in",
        "buttonLabel": "Sign in",
        "rememberMe": "Remember me",
        "forgotPasswordLink": "Forgot password?",
        "logout": "Logout"
    },
    "forgotPassword": {
        "title": "Forgot password",
        "buttonLabel": "Reset password"
    },
    "forms": {
        "email": "Email address",
        "phone": "Phone",
        "password": "Password",
        "name": "Fullname",
        "fname": "First name",
        "lname": "Last name",
        "confirmPassword": "Confirmation password",
        "oldPassword": "Old Password",
        "newPassword": "New Password",
        "retypeNewPassword": "Retype new password",
        "Account status": "Account status"
    },
    "register": {
        "title": "Create an account",
        "buttonLabel": "Create a new account"
    },
    "products": {
        "title": "Products",
        "refresh": "Refresh",
        "synchronize": "Synchronize",
        "create_bulk_skus": "Create Bulk SKUs",
        "create_update_skus": "Create/Update SKUs",
        "search_field": "Search product by title",
        "selected": "Selected",
        "singular": "Product",
        "plural": "Products",
        "Product title": "Product title",
        "variants": "Variants",
        "Last updated": "Last updated",
        "Status": "Status",
        "Actions": "Actions"
    },
    "collections": {
        "title": "Collections",
        "refresh": "Refresh",
        "synchronize": "Synchronize",
        "map_value": "Map value",
        "search_field": "Search collection by title",
        "singular": "Collection",
        "plural": "Collections",
        "footer_note_1": "The only editable field is the collection number, which remains unchanged on Shopify collections. This function solely maps collection numbers to create custom SKUs.",
        "footer_note_2": "To edit, please double-click on the collection number cell, enter the value, and then press the \"Map Value\" button.",
        "collection name": "Collection name",
        "collection number": "Collection number",
        "Next available number": "Next available number",
        "Last updated": "Last updated",
        "Actions": "Actions"
    },
    "my account": {
        "title": "My account",
        "heading": "My profile",
        "info": "Manage your personal information",
        "security": "Security",
        "security info": "Manage your passwords and logins",
        "update button": "Update profile",
        "update password": "Update password"
    },
    "my staff": {
        "title": "My staff",
        "add staff": "Add staff",
        "footer note 1": "To modify staff permissions or adjust their account status, simply double-click on the cell/row containing their full name."
    },
    "add staff": {
        "title": "Invite member",
        "staff": "Invite staff member",
        "add staff": "Add staff",
        "invite button": "Send invite",
        "info": "Staff members are only permitted to view or handle information if access is granted to them.",
        "footer note 1": "To modify staff permissions or adjust their account status, simply double-click on the cell/row containing their full name."
    },
    "edit staff": {
        "title": "Edit staff member",
        "heading": "Staff member",
        "info": "Give staff access to your software by sending them an invitation.",
        "button text": "Update user"
    },
    "permissions": {
        "title": "Permissions",
        "PRODUCTS_MANAGE": "Manage products",
        "COLLECTIONS_MANAGE": "Manage collections",
        "SKU_CREATE_UPDATE": "Create & Update SKUs",
        "STAFF_MANAGE": "Manage staff members"
    },
    "language": {
        "select": "Select language"
    }
}
