import { Stack, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import { useEffect } from "react";
import { useAuth } from "../../providers/AuthProvider";
import LegacyPage from "../../subcomponents/LegacyPage";
import { useTranslate } from "../../providers/AppProvider";

export default function Home(){
    const { user } = useAuth();
    const { t } = useTranslate();

    useEffect(() => {
        document.title = t("home.title");
    }, []);

    return(
        <LegacyPage>
            <Layout>
                <Layout.Section>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Stack direction="row" justifyContent="start" width="100%">
                            <Typography
                                variant="h4"
                            >
                                {t("home.welcome")} {`${user.fullName}!`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Layout.Section>
            </Layout>
        </LegacyPage>
    )
}